import React from 'react'
import { Card, Col, Row, Statistic } from 'antd'
import { FileTextOutlined, UsergroupAddOutlined, DollarCircleOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { number } from '../../../helpers'

export default function MomentosGeneralContent({ report, loading }) {

	const calcAvgTicket = () => {
		if(report.data.total_invoices === 0) 
			return 0;

		return number(Math.round((report.data.total_value / report.data.total_invoices) + Number.EPSILON))
	}

	return (
		<React.Fragment>
			<Row gutter={16}>
				<Col span={12}>
					<Card bordered={false} className='mb-10'>
						<Statistic
							title="Total Facturas"
							value={report.data.total_invoices}
							precision={0}
							valueStyle={{ color: '#222' }}
							prefix={<FileTextOutlined />}
							suffix="facturas"
							loading={loading}
						/>
					</Card>
				</Col>
				<Col span={12}>
					<Card bordered={false} className='mb-10'>
						<Statistic
							title="Total Clientes"
							value={report.data.total_customers}
							precision={0}
							valueStyle={{ color: '#222' }}
							prefix={<UsergroupAddOutlined />}
							suffix="clientes"
							loading={loading}
						/>
					</Card>
				</Col>
				<Col span={12}>
					<Card bordered={false} className='mb-10'>
					<Statistic
						title="Total en Ventas"
						value={report.data.total_value}
						precision={0}
						valueStyle={{ color: '#222' }}
						prefix={<DollarCircleOutlined />}
						suffix="COP"
						loading={loading}
					/>
					</Card>
				</Col>
				<Col span={12}>
					<Card bordered={false} className='mb-10'>
					<Statistic
						title="Ticket Prom. por Factura"
						value={calcAvgTicket()}
						precision={0}
						valueStyle={{ color: '#222' }}
						prefix={<DollarCircleOutlined />}
						suffix="COP"
						loading={loading}
					/>
					</Card>
				</Col>
				{ report.data.total_call_logs !== undefined && (
					<Col span={12}>
						<Card bordered={false} className='mb-10'>
							<Statistic
								title="Total Llamadas"
								value={report.data.total_call_logs}
								precision={0}
								valueStyle={{ color: '#222' }}
								prefix={<PhoneOutlined />}
								suffix="llamadas"
								loading={loading}
							/>
						</Card>
					</Col>
				)}
				{ report.data.total_click_logs !== undefined && (
					<Col span={12}>
						<Card bordered={false} className='mb-10'>
							<Statistic
								title="Total Clics Btn WA"
								value={report.data.total_click_logs}
								precision={0}
								valueStyle={{ color: '#222' }}
								prefix={<WhatsAppOutlined />}
								suffix="clics"
								loading={loading}
							/>
						</Card>
					</Col>
				)}
			</Row>
		</React.Fragment>
	)
}