import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap'
import { Tabs } from 'antd'
import moment from 'moment'

import AdvanceFiltersForm from './partials/AdvanceFiltersForm'
import MarketingKpisTab from './partials/tabs/MarketingKpisTab'
import SalesKpisTab from './partials/tabs/SalesKpisTab'
import InvoicesTableTab from './partials/tabs/InvoicesTableTab'
import CategoriesProductsTab from './partials/tabs/CategoriesProductsTab'
import ProductsTableTab from './partials/tabs/ProductsTableTab'
import RatesTableTab from './partials/tabs/RatesTableTab'
import SmsSendsTab from './partials/tabs/SmsSendsTab'

export default function AdvanceCampaignsReport({ reportType }) {
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({})
    const [reports, setReports] = useState(null)
    const [activeKey, setActiveKey] = useState('marketing_kpis')
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            report_type: reportType,
            sent_at_from: moment().startOf('month').format('YYYY-MM-DD'),
            sent_at_until: moment().endOf('month').format('YYYY-MM-DD'),
            sold_at_from: '2015-01-01',
            sold_at_until: moment().format('YYYY-MM-DD'),
        }
    })    

    const onSubmit = values => {
        setLoading(true)
        setFilters(values)
        setReports(null)
    }

    const tabProps = {
        reports,
        setReports,
        filters,
        activeKey,
        setLoading
    }

    let tabItems = [
        {
            key: 'marketing_kpis',
            label: 'KPIs de Marketing',
            children: <MarketingKpisTab {...tabProps} />
        },
        {
            key: 'sales_kpis',
            label: 'KPIs de Ventas',
            children: <SalesKpisTab {...tabProps} />
        },
        {
            key: 'invoices_table',
            label: 'Tabla de Facturas',
            children: <InvoicesTableTab {...tabProps} />
        },
        {
            key: 'categories_products',
            label: 'Categorías Productos Vendidos',
            children: <CategoriesProductsTab {...tabProps} />
        },
        {
            key: 'products_table',
            label: 'Productos Vendidos',
            children: <ProductsTableTab {...tabProps} />
        },
        {
            key: 'rates_table',
            label: 'Tarifas Vendidas',
            children: <RatesTableTab {...tabProps} />
        }
    ]

    if(reportType === 'sms_campaigns')
        tabItems = tabItems.concat([
            {
                key: 'sms_sends',
                label: 'SMS Enviados',
                children: <SmsSendsTab {...tabProps} />
            }
        ])

    return (
        <React.Fragment>
             <Card>
				<CardBody>
					<div className='float-right'>
						<Link to="/dashboards/campaigns">
							<Button color="primary" outline>Ver otros reportes</Button>
						</Link>
					</div>
					<CardTitle>Reporte Avanzado de Campañas { reportType === 'email_campaigns' ? 'Email Marketing' : 'SMS'}</CardTitle>
					<CardSubtitle>Análisis de Campañas Momentos Spring</CardSubtitle>
				</CardBody>
			</Card>
            <Row>
                <Col lg="3" md="4" sm="12">
                    <Card>
                        <CardBody>
                            <AdvanceFiltersForm 
                                onSubmit={handleSubmit(onSubmit)}
                                loading={loading}
                                register={register}
                                errors={errors}
                                setValue={setValue} 
                                watch={watch}
                                reportType={reportType}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="9" md="8" sm="12">
                    <Card>
                        <CardBody>
                            <Tabs
                                type="card"
                                size="small"
                                items={tabItems}
                                activeKey={activeKey}
                                onChange={key => setActiveKey(key)}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}