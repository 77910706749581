import React from 'react'
import { Table } from 'antd'

import Pagination from '../../../components/Pagination'

export default function SmsSendsTable({ smsSends, reload, pagination, setPagination }) {

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id'
        },
        {
            title: 'Número Celular',
            dataIndex: 'mobile'
        },
        {
            title: 'Estado',
            dataIndex: 'status'
        },
        {
            title: 'Fecha de Solicitud',
            dataIndex: 'requested_at'
        },
        {
            title: 'Fecha de Entrega',
            dataIndex: 'delivered_at',
            render: t => t ? t : <small><em>NA</em></small>
        },
    ]

    const expandedRowRender = r => (
        <React.Fragment>
            <p className='mb-5'><b>Campaña SMS: </b>{r.sms_campaign.name}</p>
            <p className='mb-5'><b>Mensaje SMS: </b>{r.sms_campaign.message}</p>
            <p className='mb-5'><b>Mensaje de error: </b>{r.error_message}</p>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Table
                dataSource={smsSends}
                columns={columns}
                rowKey={r => r.id}
                size="small"
                pagination={false}
                expandable={{ expandedRowRender }}
            />
            <Pagination 
                pagination={pagination}
                setPagination={setPagination}
                reload={reload}
            />
        </React.Fragment>
    )
}