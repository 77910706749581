import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMultiply } from '@fortawesome/free-solid-svg-icons'
import { Empty } from 'antd'

import SmsSendsTable from '../../../SmsSends/partials/SmsSendsTable'

import { getSmsSends } from '../../../SmsSends/services'
import { handleError, parsePagination } from '../../../../helpers'

export default function SmsSendsTab({ reports, setReports, filters, activeKey, setLoading }) {
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
    
    useEffect(() => {
        if(activeKey === 'sms_sends' && reports && reports.marketing_kpis && !reports.sms_sends && filters.sent_at_from){
            getSmsSends({
                'filter[sms_campaign_id]': reports.marketing_kpis.campaigns.map(c => c.id).join(','),
                [`filter[mobile]`]: filterValue,
                include: 'smsCampaign',
                sort: '-delivered_at',
                ...pagination,
            })
                .then(res => {
                    setReports({ ...reports, sms_sends: res.data.data })
                    setPagination(parsePagination(res))
                })
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }, [ filters, activeKey, reports, setReports, setLoading, filterValue, pagination ])

    if(!filters.sent_at_from || !reports || !reports.marketing_kpis)
        return <Empty description="Debe generar el reporte primero de KPIs de Marketing" />

    if(!reports || !reports.sms_sends)
        return <Spinner animation='border' size='sm' />

    const reload = () => {
        setReports({ ...reports, sms_sends: null })
    }
 
    return (
        <React.Fragment>
            <InputGroup style={{ width: '400px' }} className='mb-10'>
                <Form.Control 
                    value={filterValue}
                    onChange={e => setFilterValue(e.target.value)}
                    placeholder="Buscar por número celular"
                />
                <Button onClick={reload}>Buscar</Button>
                <Button onClick={() => { setFilterValue(''); reload() }} variant='dark'>
                    <FontAwesomeIcon icon={faMultiply} />
                </Button>
            </InputGroup>
            <SmsSendsTable 
                smsSends={reports.sms_sends}
                reload={reload}
                pagination={pagination}
                setPagination={setPagination}
            />
        </React.Fragment>
    )
}