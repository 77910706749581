import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { logout } from '../../containers/Authentication/services'
import { userLogout } from '../../containers/Authentication/actions'
import { handleError } from '../../helpers'

export default function MainMenu({ closeMenu }) {
	const user = useSelector(state => state.auth.user)
	const dispatch = useDispatch()

	const onLogout = () => {
		logout()
			.then(() => dispatch(userLogout()))
			.catch(handleError)
	}

	const MenuLink = ({ to, label }) => (
		<li>
			<Link to={to} onClick={closeMenu}>
				{label}
			</Link>
		</li>
	)

	return (
		<React.Fragment>
			<Container>
				<UserContainer>
					<UserIcon>
						<FontAwesomeIcon icon={faUser} />
					</UserIcon>
					<UserName>{user.name}</UserName>
					<p>c.c. {user.document}</p>
					<p className="mb-0 text-link" onClick={onLogout}>Cerrar Sesión</p>
				</UserContainer>
				<MenuList>
					<MenuLink to="/dashboards/profiles" label="Perfiles Cotizador Spring" />
					<MenuLink to="/dashboards/momentos" label="Ventas Momentos Spring" />
					<MenuLink to="/dashboards/campaigns" label="Campañas Momentos Spring" />
					<MenuLink to="/dashboards/resell" label="Recompra Fiel Momentos" />
				</MenuList>
				<p>Correo de soporte: soporte@smart4.com.co</p>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	z-index: 9;	
	padding-top: 50px;
	width: 250px;
	background-color: white;
	text-align: center;
	-webkit-box-shadow: 3px 0 12px 0 #b3b3b3;
	box-shadow: 3px 0 12px 0 #b3b3b3;
	overflow-y: scroll;
`

const UserContainer = styled.div`
	padding: 40px 20px 30px;
	background-color: #e8e8e8;
`

const UserName = styled.h6`
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 600;
`

const UserIcon = styled.div`
	height: 4.2rem;
	width: 4.2rem;
	font-size: 1.9rem;
	background: #f8f8f8;
	border: 4px solid #fff;
	border-radius: 50%;
	color: #e0e0e0;
	margin: 0px auto 15px;
	padding-top: 8px;
`

const MenuList = styled.ul`
	list-style: none;
	margin: 0px 0px 30px;
	padding: 0;
	& li {
		padding: 0;
    	margin: 0;
	}
	& li a {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		border-bottom: 1px solid #e8e8e8;
		width: 100%;
	}
	& li a:hover {
		cursor: pointer;
		background-color: #333;
		color: #fff !important;
	}
`